<template>
  <div
    class="artists-grid grid"
    :class="{ 'artists-grid--touched': isTouched }"
  >
    <WidgetHeading class="title" :content="title" />
    <SmoothReflow tag="ul" class="tiles" :enabled="isTouched" role="list">
      <li
        v-for="(artist, idx) in artists"
        :key="artist.id"
        class="tile"
        :class="{ 'tile--hidden': idx >= visibleTiles }"
      >
        <ArtistCard big :artist="artist" />
      </li>
    </SmoothReflow>
    <div class="button-section">
      <ClientOnly>
        <template #default>
          <BaseButton
            v-if="!isFullyExpanded"
            class="button"
            :aria-label="t('a11y.load-more-artists')"
            color="primary"
            @click="loadMore"
          >
            <span v-html="t('load-more')" />
          </BaseButton>
          <BaseButton
            v-else-if="link"
            class="button"
            :base-link="{ ...link, label: t('a11y.see-more-artists') }"
            color="primary"
          >
            <span v-html="t('see-more')" />
          </BaseButton>
        </template>
        <template #fallback>
          <!-- for some reason the div prevents a vue error -->
          <div>
            <BaseButton
              v-if="link"
              class="button"
              :base-link="{ ...link, label: t('a11y.see-more-artists') }"
              color="primary"
            >
              <span v-html="t('see-more')" />
            </BaseButton>
          </div>
        </template>
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArtistWithNearestEvent, PublicLink } from '@/service/__generated-api'

const props = defineProps<{
  title: string
  link: PublicLink | null
  artists: ArtistWithNearestEvent[]
}>()

const { t } = useI18n()

// prevent hydration error
const isTouched = ref(false)
const expandedRows = ref(0)

const isDesktop = useIsDesktop()
const initialRows = computed(() => (isDesktop.value ? 1 : 2))
const visibleTiles = computed(() => {
  const tilesPerRow = isDesktop.value ? 3 : 2
  return tilesPerRow * (expandedRows.value + initialRows.value)
})

const isFullyExpanded = computed(
  () => props.artists && visibleTiles.value >= props.artists.length
)

const loadMore = () => {
  expandedRows.value++
  isTouched.value = true
}
</script>

<style lang="scss" scoped>
.title,
.button-section,
.tiles {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
}

.title {
  margin-bottom: rem(16px);

  @include media-up(md) {
    margin-bottom: rem(24px);
  }
}

.tiles {
  @include reset-list;
  @include tiles(
    (
      xs: 2 rem(12px),
      md: 2,
      lg: 3
    )
  );
}

.artists-grid--touched .tile--hidden {
  display: none;
}

.artists-grid:not(.artists-grid--touched) {
  @include media-up(lg) {
    .tile:nth-child(n + 4) {
      display: none;
    }
  }

  .tile:nth-child(n + 5) {
    display: none;
  }
}

.button-section {
  @include center-content;
  margin-top: rem(40px);

  @include media-up(md) {
    margin-top: rem(56px);
  }

  @include media-up(lg) {
    margin-top: rem(64px);
  }
}

.tile {
  transition: transform $transition 100ms;
}

.tile:hover ~ .tile,
.tile:focus-visible ~ .tile,
.tile:has(~ .tile:hover),
.tile:has(~ .tile:focus-visible) {
  transform: scale(0.9);
}
</style>
